<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import DatePicker from "vue2-datepicker";
import Multiselect from "vue-multiselect";
import Timeline from "@/components/dashboard/recommendations/timeline"
import FilterC from "@/components/dashboard/recommendations/filter"
import Drawer from "vue-simple-drawer";

import helpers from '../../../helpers'


import {
  analyticsMethods,
} from "@/state/helpers";

/**
 * Dashboard Component
 */
export default {
  page: {
    title: "Recommendations Analytics",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    DatePicker,
    Multiselect,
    Timeline,
    FilterC,
    Drawer
  },
  data() {
    return {
      title: this.$t('kpi.title'),
      items: [],

      showModal: false,
      isLoading: false,
      fullPage: true,
      canCancel: false,
      query: '',
      user: JSON.parse(localStorage.getItem("auth.currentUser")).user,
      dateFilter: null,
      disabledBefore: new Date().setDate(new Date().getDate() - 90),
      shortcuts: [
        {
          text: this.$t('kpi.filter_date_today'),
          onClick() {
            const start = new Date();
            const end = new Date();

            end.setTime(end.getTime() + 0 * 24 * 3600 * 1000);
            const date = [start, end];

            return date;
          },
        },
        {
          text: this.$t('kpi.filter_date_yesterday'),
          onClick() {

            const start = new Date();
            const end = new Date();

            start.setTime(start.getTime() - 1 * 24 * 3600 * 1000);
            const date = [start, end];

            return date;
          },
        },
        {
          text: this.$t('kpi.filter_date_this_week'),
          onClick() {

            var curr = new Date; // get current date
            var first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
            var last = first + 6; // last day is the first day + 6

            var firstday = new Date(curr.setDate(first));
            var lastday = new Date(curr.setDate(last));

            const date = [firstday, lastday];

            return date;
          },
        },
        {
          text: this.$t('kpi.filter_date_this_month'),
          onClick() {
            var today = new Date; // get current date
            const firstday = new Date(today.getFullYear(), today.getMonth(), 1);
            //const lastday = new Date(today.getFullYear(), today.getMonth() + 1, 0);

            const date = [firstday, today];
            return date;
          },
        },
        {
          text: this.$t('kpi.filter_date_this_last_week'),
          onClick() {
            const today = new Date();
            const lastWeek = new Date(today);
            lastWeek.setDate(today.getDate() - 7); // Resta 7 días para obtener la semana pasada

            // Obtén el primer día de la semana pasada (domingo)
            const firstDay = new Date(lastWeek);
            firstDay.setDate(lastWeek.getDate() - lastWeek.getDay());

            // Obtén el último día de la semana pasada (sábado)
            const lastDay = new Date(lastWeek);
            lastDay.setDate(lastWeek.getDate() - lastWeek.getDay() + 6);

            const date = [firstDay, lastDay];

            return date;
          },
        },
        {
          text: this.$t('kpi.filter_date_this_last_month'),
          onClick() {
            const today = new Date();

            // Calculate the first day of the last month
            const firstDay = new Date(today.getFullYear(), today.getMonth() - 1, 1);

            // Calculate the last day of the last month
            const lastDay = new Date(today.getFullYear(), today.getMonth(), 0);
            const date = [firstDay, lastDay];

            return date;
          },
        },
        {
          text: this.$t('kpi.filter_date_this_last_7_days'),
          onClick() {
            const today = new Date();
            const lastDay = new Date(today); // Last day is today
            const firstDay = new Date(today);
            firstDay.setDate(today.getDate() - 6); // First day is 7 days ago

            const date = [firstDay, lastDay];
            return date;
          },
        },
        {
          text: this.$t('kpi.filter_date_this_last_30_days'),
          onClick() {

            const today = new Date();
            const lastDay = new Date(today); // Last day is today
            const firstDay = new Date(today);
            firstDay.setDate(today.getDate() - 29); // First day is 30 days ago

            const date = [firstDay, lastDay];
            return date;
          },
        },
      ],

      isLocal: window.config.local ? true : false,
      reco_impressions: '-',
      reco_total_impressions: '-',
      reco_ctr: '-',
      reco_cr: '-',
      reco_directRevenue: '-',
      reco_assistedRevenue: '-',
      reco_customers:'-',

      reco_impressions_previous: '-',
      reco_total_impressions_previous: '-',
      reco_ctr_previous: '-',
      reco_cr_previous: '-',
      reco_directRevenue_previous: '-',
      reco_assistedRevenue_previous: '-',
      reco_customers_previous:'-',
      previousDates: '',
      recoAnalytics:[],
      previouseRecoAnalytics: [],  
      windows: [
        { id: '1', label: this.$t('reco_analyze.attribution_window_session') },
        { id: '7', label: this.$t('reco_analyze.attribution_window_7days') },
        { id: '14', label: this.$t('reco_analyze.attribution_window_14days') },
        { id: '30', label: this.$t('reco_analyze.attribution_window_30days') }
      ],
      selectedWindow: { id: '7', label: this.$t('reco_analyze.attribution_window_7days') },
      filters: [],
      periodLength: 0,
      openRightDrawer: false,
    };
  },
  mounted() {

    const today = new Date();
    const lastDay = new Date(today);
    const firstDay = new Date(today);
    firstDay.setDate(today.getDate() - 29);

    this.dateFilter = [firstDay, lastDay];

  },
  methods: {
    ...analyticsMethods,
    ...helpers,

    removeAllFilter(){
      this.$refs.filterRef.removeAll();
    },

    rightDrawer(){
      this.openRightDrawer = false;
    },

    onSelectedWindowChanged() {
      const nextDay = new Date(this.dateFilter[1]);
      nextDay.setDate(this.dateFilter[1].getDate() + 1)

      let query = `project=${localStorage.getItem('current_project')}`
      query += `&dateFrom=${this.formatDate(this.dateFilter[0])}&dateTo=${this.formatDate(nextDay)}`;

      this.applyFilter(query)
    },
    
    applyFilter(query) {

      const loader = this.$loading.show();

      query += `&attributionWindow=${this.selectedWindow.id}`
      
      if(this.filters.length > 0){
        query+= this.getCustomFilterQuery();
      }

      this.query = query;
      this.getLastSyncDate().then((res) => {
        if (res && res.data && res.data.last_replication_date) {
          this.lastSyncDate = new Date(res.data.last_replication_date).toLocaleDateString() + ' ' + new Date(res.data.last_replication_date).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
        }
      });

      if(!query.includes('key')){
          query+=`&key=all`
      }

      if(window.config.local){
        this.getRecommendationsCustomers(query).then((resCustomer)=>{
          this.reco_customers = resCustomer.data?.length > 0 ? resCustomer.data[0].count : 0;
         });
      }
      

      this.getRecommendationsIndicators(query).then((res)=>{
        this.recoAnalytics = res.data;

        this.reco_impressions = res.data.length > 0 ? this.getSummaryByKey('personalized_sessions', this.recoAnalytics): 0; 
        this.customers = res.data.length > 0 ? this.getSummaryByKey('customers', this.recoAnalytics): 0;
        this.reco_directRevenue = res.data.length > 0 ? this.getSummaryByKey('direct_revenue', this.recoAnalytics): 0; 
        this.reco_assistedRevenue = res.data.length > 0 ? this.getSummaryByKey('assisted_revenue', this.recoAnalytics): 0; 
        this.reco_ctr = this.reco_impressions == 0? 0 :  this.parsePercentage( this.getSummaryByKey('ctr', this.recoAnalytics)/this.getSummaryByKey('personalized_sessions', this.recoAnalytics) * 100) ;
        this.reco_cr = this.reco_impressions == 0? 0 :  this.parsePercentage(this.getSummaryByKey('cr', this.recoAnalytics)/this.getSummaryByKey('personalized_sessions', this.recoAnalytics) * 100) ;
        this.reco_purchases = res.data.length > 0 ? this.getSummaryByKey('recommended_purchases', this.recoAnalytics): 0;

        if(!window.config.local)
          this.reco_customers = res.data.length > 0 ? this.getSummaryByKey('customers', this.recoAnalytics): 0;

        if(this.filters.filter(f=>f.key=='compare').length>0){
         this.getPreviousRecommendationsIndicators();
        }

       }).finally(()=>{
        loader.hide();
       });

       
    },
    dowloadCRReport() {

      const loader = this.$loading.show();
      const params = `project=${localStorage.getItem('current_project')}&dateFrom=${this.formatDate(this.dateFilter[0])}&dateTo=${this.formatDate(this.dateFilter[1])}`
      this.getDownloadRecoCRReport(params).then(res => {

        const csvUrl = URL.createObjectURL(new Blob([res]));
        const link = document.createElement('a');
        link.href = csvUrl;
        link.setAttribute('download', 'report.csv');
        document.body.appendChild(link);
        link.click();
        URL.revokeObjectURL(csvUrl);

        this.$refs.actionsDropdown.hide();

      }).finally(() => {
        loader.hide();
      })
    },
    onRemoveFilter(filter){
      this.filters = this.filters.filter(f=> f.key != filter.key);
      if(filter.key== 'compare'){
        this.previouseRecoAnalytics = [];
      }

      const nextDay = new Date(this.dateFilter[1]);
      nextDay.setDate(this.dateFilter[1].getDate() + 1)

      let query = `project=${localStorage.getItem('current_project')}`
      query+= `&dateFrom=${this.formatDate(this.dateFilter[0])}&dateTo=${this.formatDate(nextDay)}`;

      this.applyFilter(query)

    },
    onComparePreviousPeriod(){
      if(this.filters.filter(f=>f.key=='compare').length==0){
        this.filters.push({
          badge: this.$t('common.compare_badge'),
          key: 'compare'
        })
        this.applyFilter(this.query);
      }
      this.$refs.actionsDropdown.hide();
    },
    getPreviousRecommendationsIndicators (){

      const dates = this.getPreviousPeriod(this.dateFilter[0],this.dateFilter[1]);
      const nextDay = new Date(dates.previousDateTo);
      nextDay.setDate(dates.previousDateTo.getDate() + 1)
      
      this.previousDates = dates;

      let queryPrevious = `project=${localStorage.getItem('current_project')}`
      queryPrevious+= `&dateFrom=${this.formatDate(dates.previousDateFrom)}&dateTo=${this.formatDate(nextDay)}`;  
      if(this.filters.length > 0){
        queryPrevious+= this.getCustomFilterQuery();
      }

      if(!queryPrevious.includes('key')){
        queryPrevious+=`&key=all`
      }

      if(window.config.local){
        this.getRecommendationsCustomers(queryPrevious).then((resprev)=>{
          const previous_customers = resprev.data?.length > 0 ? resprev.data[0].count : 0;
          this.reco_customers_previous = this.calculatePercentageChange(this.reco_customers, previous_customers) ;
        });
      }
      
      this.getRecommendationsIndicators(queryPrevious).then((res)=>{

        this.previouseRecoAnalytics = res.data;
        
        const previous_impressions = res.data.length > 0 ? this.getSummaryByKey('personalized_sessions', res.data): 0;
        this.reco_impressions_previous = this.calculatePercentageChange(this.reco_impressions, previous_impressions) ;

        const previous_reco_directRevenue = res.data.length > 0 ? this.getSummaryByKey('direct_revenue', res.data): 0;
        this.reco_directRevenue_previous = this.calculatePercentageChange(this.reco_directRevenue, previous_reco_directRevenue) ;

        const previous_reco_assistedRevenue = res.data.length > 0 ? this.getSummaryByKey('assisted_revenue', res.data): 0;
        this.reco_assistedRevenue_previous = this.calculatePercentageChange(this.reco_assistedRevenue, previous_reco_assistedRevenue) ;

        const previous_ctr = previous_impressions == 0 ? 0 : this.parsePercentage( this.getSummaryByKey('ctr', res.data)/this.getSummaryByKey('personalized_sessions', res.data) * 100);
        
        this.reco_ctr_previous = previous_ctr == 0 && this.reco_ctr>0 ?100 : previous_ctr==0 ? 0 : this.calculatePercentageChange(this.reco_ctr, previous_ctr) ;
        
        const previous_cr = previous_impressions == 0? 0 : this.parsePercentage(this.getSummaryByKey('cr', res.data)/this.getSummaryByKey('personalized_sessions', res.data) * 100) ;
        this.reco_cr_previous = previous_cr == 0 && this.reco_cr>0 ?100 : previous_cr==0 ? 0 : this.calculatePercentageChange(this.reco_cr, previous_cr) ;
        
        if(!window.config.local){
          const previous_customers = res.data.length > 0 ? this.getSummaryByKey('customers', res.data): 0;
          this.reco_customers_previous = this.calculatePercentageChange(this.reco_customers, previous_customers) ;
        }
      });
    },
    getCustomFilterQuery(){
      let q = '';
      if(this.filters.length>0){
        this.filters.forEach((f)=>{
          if(f.key!='compare'){
            q+= `&key=${f.key}&value=${f.value}`
          }
        });
      }
      return q;
    },
    applyCustomFilter(){
      const nextDay = new Date(this.dateFilter[1]);
      nextDay.setDate(this.dateFilter[1].getDate() + 1)

      let query = `project=${localStorage.getItem('current_project')}`
      query+= `&dateFrom=${this.formatDate(this.dateFilter[0])}&dateTo=${this.formatDate(nextDay)}`;
      
      const fs = this.$refs.filterRef.getAllFilters();

      if(this.filters.filter(f=>f.key=="compare").length > 0){
        fs.push({
          badge: `${this.$t('common.compare_badge')}`,
          key: 'compare'
        })
      }

      this.filters = fs;
      
      this.applyFilter(query)
      this.openRightDrawer = false;
    },
    onFilterClicked(){
      this.openRightDrawer=true; 
      setTimeout(()=>{
        this.$refs.filterRef.setAllFilter(this.filters)
      },1000)
    },
  },
  watch: {
    dateFilter: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          const nextDay = new Date(newVal[1]);
          nextDay.setDate(newVal[1].getDate() + 1)

          let query = `project=${localStorage.getItem('current_project')}`
          query += `&dateFrom=${this.formatDate(newVal[0])}&dateTo=${this.formatDate(nextDay)}`;
          const timeDifference = newVal[1].getTime() - newVal[0].getTime();
          this.periodLength =  Math.trunc(timeDifference / (1000 * 3600 * 24) + 1);
          
          this.applyFilter(query)
        }
      }
    }
  }
};
</script>

<template>
  <Layout>
    <div class="row mb-4">
      <div class="col-lg-12 mb-3">
        <div class="d-flex align-items-center">
          <div class="ms-3 flex-grow-1 mb-0">
            <h5 class="mb-0 card-title" style="font-size: 17px;">{{ $t('reco_analyze.title') }}</h5>
            <p class="text-muted mb-0">{{ $t('reco_analyze.subtitle') }}</p>
            <a class="small text-primary mb-0" href="https://academy.gopersonal.ai/guia-de-usuario/analytics/recomendaciones" target="_blank">{{ $t('common.visit_academy') }} <i class="bx bx-link-external"></i></a>
          </div>
          <div class="text-end w-50">
            <div class="row align-items-center justify-content-end">
              <div class="col m-auto pe-2 ps-0" v-if="false">
                <p class="mb-0 text-start" v-if="false">{{ $t('reco_analyze.attribution_window') }}</p>
                <multiselect :options="windows" :selectLabel="$t('common.select')"
                  :deselectLabel="$t('common.deselect')" track-by="id" label="label" :show-labels="false"
                  :multiple="false" v-model="selectedWindow" @input="onSelectedWindowChanged" v-if="false">
                </multiselect>
              </div>
              <div class="col-auto ps-0 w-50">
                <div class="flex">
                    <date-picker v-model="dateFilter" :shortcuts="shortcuts" placeholder="Selecioná la fecha" range
                      :format="'DD.MM.YYYY'" :clearable="false"
                      :disabled-date="(date) => date >= new Date() || date < new Date().setDate((new Date()).getDate() - 90)">
                    </date-picker>
                </div>
              </div>
              <div class="col-auto ms-0 ps-0">
                <b-dropdown ref="actionsDropdown" ngbDropdown menu-class="dropdown-menu-sm dropdown-menu-end pb-0" right variant="primary">
                    <template v-slot:button-content class="ms-2">
                      <i class="mdi mdi-apps me-1 text-white"></i>
                      <span class="d-none d-sm-inline-block" ngbDropdownToggle>
                        {{$t('common.actions')}}
                        <i class="mdi mdi-chevron-down"></i>
                      </span>
                    </template>
                    
                    <a class="dropdown-item" href="javascript: void(0);" v-on:click="onFilterClicked">
                      <i class="mdi mdi-filter-variant"></i>
                      {{ $t('common.filter') }}
                    </a>
                    <div class="dropdown-divider"></div>
                    <a class="dropdown-item" href="javascript: void(0);" v-on:click="dowloadCRReport">
                      <i class="mdi mdi-export-variant"></i>
                      {{ $t('common.export') }}
                    </a>
                    <div class="dropdown-divider"></div>
                    <a class="dropdown-item" href="javascript: void(0);" v-on:click="onComparePreviousPeriod">
                      <i class="mdi mdi-compare"></i>
                      {{ $t('common.compare') }}
                    </a>
                    <div class="dropdown-divider mb-0"></div>
                  </b-dropdown>
              </div>
            </div>
          </div>
        </div>
        <div class="badge bg-primary m-1" v-for="f in filters" :key="f.key">
          <div class="d-flex align-items-center justify-content-between">
            <span class="pe-2">{{ f.key!= 'compare' ? f.badge : `${f.badge} (${periodLength} ${periodLength>1 ? $t('common.days_badge') : $t('common.day_badge')})` }}</span>
              <i class="fa fa-times interact " v-on:click="onRemoveFilter(f)"></i>
          </div>
        </div>
      </div>
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-sm-2 text-center">
                <div>
                  <p class="text-muted text-truncate mb-0 d-inline-flex font-size-13">
                    {{ $t('reco_analyze.customers') }} <i
                      class="mdi mdi-help-circle-outline ms-1 text-primary interact"
                      v-b-popover.hover.bottom="$t('reco_analyze.customers_tooltip')" />
                  </p>
                  <h3 class="mb-0" v-b-popover.hover.bottom="reco_customers">
                    {{ parseCountReduced(reco_customers) }}</h3>
                  <span class="badge badge-soft-success font-size-12" :class="{
                        'badge-soft-warning': reco_customers_previous === 0,
                        'badge-soft-danger': reco_customers_previous < 0,
                        'badge-soft-success': reco_customers_previous > 0,
                        // eslint-disable-next-line vue/no-parsing-error
                        }" v-if="filters.filter(f=>f.key=='compare').length > 0"> {{ `${reco_customers_previous > 0 ? '+'  : '' } ${ reco_customers_previous}%` }}</span>
                </div>
              </div>
              <div class="col-sm-2 text-center">
                <div>
                  <p class="text-muted text-truncate mb-0 d-inline-flex font-size-13">
                    {{ $t('reco_analyze.sessions') }} <i class="mdi mdi-help-circle-outline ms-1 text-primary interact"
                      v-b-popover.hover.bottom="$t('reco_analyze.sessions_tooltip')" />
                  </p>
                  <h3 class="mb-0" v-b-popover.hover.bottom="reco_impressions">{{ parseCountReduced(reco_impressions) }}</h3>
                  <span class="badge badge-soft-success font-size-12" :class="{
                        'badge-soft-warning': reco_impressions_previous === 0,
                        'badge-soft-danger': reco_impressions_previous < 0,
                        'badge-soft-success': reco_impressions_previous > 0,
                        // eslint-disable-next-line vue/no-parsing-error
                        }" v-if="filters.filter(f=>f.key=='compare').length > 0"> {{ `${reco_impressions_previous > 0 ? '+' : '' } ${reco_impressions_previous}%` }}</span>
                </div>
              </div>
              <div class="col-sm-2 text-center">
                <div>
                  <p class="text-muted text-truncate mb-0 d-inline-flex font-size-13">
                    {{ $t('reco_analyze.click_rate') }} <i
                      class="mdi mdi-help-circle-outline ms-1 text-primary interact"
                      v-b-popover.hover.bottom="$t('reco_analyze.ctr_tooltip')" />
                  </p>
                  <h3 class="mb-0">{{ reco_ctr }}%</h3>
                  <span class="badge badge-soft-success font-size-12" :class="{
                        'badge-soft-warning': reco_ctr_previous === 0,
                        'badge-soft-danger': reco_ctr_previous < 0,
                        'badge-soft-success': reco_ctr_previous > 0,
                        // eslint-disable-next-line vue/no-parsing-error
                        }" v-if="filters.filter(f=>f.key=='compare').length > 0"> {{ `${reco_ctr_previous > 0 ? '+' : '' } ${reco_ctr_previous}%` }}</span>
                </div>
              </div>
              <div class="col-sm-2 text-center" style="border-right-style: solid; border-right-color: lightgray;">
                <div>
                  <p class="text-muted text-truncate mb-0 d-inline-flex font-size-13">
                    {{ $t('reco_analyze.conversion_rate') }} <i
                      class="mdi mdi-help-circle-outline ms-1 text-primary interact"
                      v-b-popover.hover.bottom="$t('reco_analyze.cr_tooltip')" />
                  </p>
                  <h3 class="mb-0">{{ reco_cr }}%</h3>
                  <span class="badge badge-soft-success font-size-12" :class="{
                        'badge-soft-warning': reco_cr_previous === 0,
                        'badge-soft-danger': reco_cr_previous < 0,
                        'badge-soft-success': reco_cr_previous > 0,
                        // eslint-disable-next-line vue/no-parsing-error
                        }" v-if="filters.filter(f=>f.key=='compare').length > 0"> {{ `${reco_cr_previous > 0 ? '+' : '' } ${reco_cr_previous}%` }}</span>
                </div>
              </div>
              <div class="col-sm-2 text-center">
                <div>
                  <p class="text-muted text-truncate mb-0 d-inline-flex font-size-13">
                    {{ $t('reco_analyze.direct_revenue') }} <i
                      class="mdi mdi-help-circle-outline ms-1 text-primary interact"
                      v-b-popover.hover.bottom="$t('reco_analyze.direct_revenue_tooltip')" />
                  </p>
                  <h3 class="mb-0" v-b-popover.hover.bottom="`$${parseMoneyWithLocale(reco_directRevenue)}`">
                    {{ `$${parseMoneyReducedWithLocale(reco_directRevenue)}` }}</h3>
                    <span class="badge badge-soft-success font-size-12" :class="{
                        'badge-soft-warning': reco_directRevenue_previous === 0,
                        'badge-soft-danger': reco_directRevenue_previous < 0,
                        'badge-soft-success': reco_directRevenue_previous > 0,
                        // eslint-disable-next-line vue/no-parsing-error
                        }" v-if="filters.filter(f=>f.key=='compare').length > 0"> {{ `${reco_directRevenue_previous > 0 ? '+' : '' } ${reco_directRevenue_previous}%` }}</span>
                </div>
              </div>
              <div class="col-sm-2 text-center">
                <div>
                  <p class="text-muted text-truncate mb-0 d-inline-flex font-size-13">
                    {{ $t('reco_analyze.assisted_revenue') }} <i
                      class="mdi mdi-help-circle-outline ms-1 text-primary interact"
                      v-b-popover.hover.left="$t('reco_analyze.assisted_revenue_tooltip')" />
                  </p>
                  <h3 class="mb-0" v-b-popover.hover.bottom="`$${parseMoneyWithLocale(reco_assistedRevenue)}`">
                    {{ `$${parseMoneyReducedWithLocale(reco_assistedRevenue)}` }}</h3>
                    <span class="badge badge-soft-success font-size-12" :class="{
                        'badge-soft-warning': reco_assistedRevenue_previous === 0,
                        'badge-soft-danger': reco_assistedRevenue_previous < 0,
                        'badge-soft-success': reco_assistedRevenue_previous > 0,
                        // eslint-disable-next-line vue/no-parsing-error
                        }" v-if="filters.filter(f=>f.key=='compare').length > 0"> {{ `${reco_assistedRevenue_previous > 0 ? '+' : '' } ${reco_assistedRevenue_previous}%` }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-12">
        <div class="card">
          <Timeline :query="query" :trendings="recoAnalytics" :compare="filters.filter(f=>f.key=='compare').length > 0" :oldTrendings="previouseRecoAnalytics" :periodLength="periodLength"/>
        </div>
      </div>
    </div>
    <Drawer
      @close="rightDrawer"
      :align="'right'"
      :closeable="true"
      :maskClosable="true"
      :zIndex="1002"
      class="h-100">
          <div v-if="openRightDrawer" class="h-100">
              <div class="offcanvas-header">
                  <h4 class="offcanvas-title" id="offcanvasExampleLabel">
                  <i class="bx bx-gear font-size-16 align-middle me-1"></i>
                  {{$t('filter.title')}}
                  </h4>
              </div>
              <hr class="mt-1" />
              <div class="offcanvas-body flex-grow-1 h-100">
                <div>
                  <FilterC ref="filterRef"></FilterC>
                </div>
                <div class="position-fixed bottom-0 end-0 p-3">
                  <button class="btn btn-secondary me-2" @click="removeAllFilter">{{$t('filter.clean')}}</button>
                  <button class="btn btn-primary me-2" @click="applyCustomFilter">{{$t('filter.apply')}}</button>
                </div>
              </div>
            </div>
    </Drawer>  
  </Layout>
</template>

<style scoped>
.top-right-menu {
  cursor: pointer;
  position: absolute;
  top: 5px;
  right: 5px;
}
</style>

<style>
.mx-btn-shortcut {
  font-size: 13px !important;
  font-family: var(--bs-body-font-family);
  padding: 0 4px;
  color: var(--bs-primary)
}

.mx-datepicker-sidebar+.mx-datepicker-content {
  margin-left: 150px !important;
  border-left: 1px solid #e8e8e8;
}

.mx-datepicker-sidebar {
  width: 120px;
}

.cell.active {
  background-color: var(--bs-primary) !important;
}

.dropdown-toggle {
  padding-top: 5px !important;
}
</style>
