<script>
import Multiselect from "vue-multiselect";
import helpers from "../../../helpers";

import {
  analyticsMethods,
} from "@/state/helpers";

export default {
  data() {
    return {
      series: [],
      chartoptions: {
        chart: {
          height: 350,
          type: "area",
          sparkline: {
              enabled: false,
            },
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false 
          }
        },
        colors: ["#5e40bf", "#f1b44c"],
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
          width: 2,
          dashArray: [0, 5]
        },
        fill: {
          type: "gradient",
          gradient: {
            shadeIntensity: 1,
            inverseColors: false,
            opacityFrom: 0.45,
            opacityTo: 0.05,
            stops: [20, 100, 100, 100],
          },
        },
        yaxis:{
          labels: {
            show:true
          }
        }          
      },
      selection: "all",
      values: [
        {id: 'sessions', label: this.$t('reco_analyze.sessions')},
        {id: 'ctr', label: 'Clicks'},
        {id: 'cr', label: this.$t('reco_analyze.conversions')},
        {id: 'direct_revenue', label: this.$t('reco_analyze.direct_revenue')},
        {id: 'assisted_revenue', label: this.$t('reco_analyze.assisted_revenue')},
        {id: 'recommended_purchases', label: this.$t('reco_analyze.recommended_purchases')},
      ],
      auxValue: {id: 'sessions', label: this.$t('reco_analyze.sessions')},
      groupButtonSelected: 'day',
      monthlyTendings: [],
      weeklyTendings: [],
      monthlyPreviousTendings: [],
      weeklyPreviousTendings: [],
    };
  },
  components:{Multiselect},
  props: {
    query : String,
    trendings: Array,
    compare: Boolean,
    oldTrendings: Array,
    periodLength: Number
  },
  mounted(){
    const self = this;

    this.chartoptions.xaxis = {
      labels: {
        formatter: function (valor) {
          return self.parseDateWithLocale(new Date(valor),self.getDateConfigByOptions());
        }
      }
    }
    this.chartoptions.yaxis = {
      labels: {
        formatter: function (valor) {
          if(self.auxValue.id =='direct_revenue' || self.auxValue.id =='assisted_revenue'){
            return `$${self.parseMoneyWithLocale(valor)}`;
          }
          else if(self.auxValue.id =='ctr'){
            return `${self.parsePercentage(valor)}%`;
          }else{
            return valor;
          }
        }
      }
    }
    this.$refs.TrendsChart.updateOptions(this.chartoptions);
  },
  computed: {
    getSubtitleText(){
      switch(this.auxValue.id){
        case 'sessions':
          return this.$t('reco_analyze.sessions_trend_subtitle')
        case 'impressions':
          return this.$t('reco_analyze.impressions_trend_subtitle')
        case 'ctr':
          return this.$t('reco_analyze.ctr_trend_subtitle')
        case 'cr':
          return this.$t('reco_analyze.cr_trend_subtitle')
        case 'direct_revenue':
          return this.$t('reco_analyze.direct_revenue_trend_subtitle')
        case 'assisted_revenue':
          return this.$t('reco_analyze.assisted_revenue_trend_subtitle')
        case 'recommended_purchases':
        return this.$t('reco_analyze.recommended_purchases_trend_subtitle')
      }
      return ''
    },
  },
  methods: {
    ...analyticsMethods,
    ...helpers,
    
    getDateConfigByOptions(){
      if(this.groupButtonSelected == 'day'){
        return { month: 'short', day: 'numeric', timeZone: 'UTC'}
      }
      else if(this.groupButtonSelected == 'week'){
        return {month: 'long', day: 'numeric', timeZone: 'UTC'}
      }else{
        return {month: 'long', timeZone: 'UTC'}
      }
    },
    aggregateMonthly(data) {
      const monthlyData = {};

      data.forEach(item => {
        const date = new Date(item.date);
        const month = date.getUTCMonth() + 1; 
        const year = date.getUTCFullYear();
        const monthYear = `${year}-${month < 10 ? '0' + month : month}`;

        if (!monthlyData[monthYear]) {
          monthlyData[monthYear] = {
            date: monthYear,
            personalized_sessions: 0,
            direct_revenue: 0,
            assisted_revenue:0,
            ctr:0,
            cr:0,
            impressions:0,
            recommended_purchases: 0
          };
        }

        monthlyData[monthYear].personalized_sessions += item.personalized_sessions;
        monthlyData[monthYear].direct_revenue += parseFloat(item.direct_revenue);
        monthlyData[monthYear].assisted_revenue += parseFloat(item.assisted_revenue);
        monthlyData[monthYear].ctr += item.ctr
        monthlyData[monthYear].impressions += item.impressions;
        monthlyData[monthYear].cr += item.cr;
        monthlyData[monthYear].cr += item.recommended_purchases;
      });

      this.monthlyTendings = Object.values(monthlyData);
    },
    aggregatePreviousMonthly(data) {
      const monthlyData = {};

      data.forEach(item => {
        const date = new Date(item.date);
        date.setDate(date.getDate() + this.periodLength); 
        const month = date.getUTCMonth() + 1; 
        const year = date.getUTCFullYear();
        const monthYear = `${year}-${month < 10 ? '0' + month : month}`;

        if (!monthlyData[monthYear]) {
          monthlyData[monthYear] = {
            date: monthYear,
            personalized_sessions: 0,
            direct_revenue: 0,
            assisted_revenue:0,
            ctr:0,
            cr:0,
            impressions:0,
            recommended_purchases: 0
          };
        }

        monthlyData[monthYear].personalized_sessions += item.personalized_sessions;
        monthlyData[monthYear].direct_revenue += parseFloat(item.direct_revenue);
        monthlyData[monthYear].assisted_revenue += parseFloat(item.assisted_revenue);
        monthlyData[monthYear].ctr += item.ctr
        monthlyData[monthYear].impressions += item.impressions;
        monthlyData[monthYear].cr += item.cr;
        monthlyData[monthYear].cr += item.recommended_purchases;
      });

      this.monthlyPreviousTendings = Object.values(monthlyData);
    },
    getWeekNumber(date) {
      const startOfYear = new Date(Date.UTC(date.getUTCFullYear(), 0, 1));
      const pastDaysOfYear = (date - startOfYear) / 86400000;
      return Math.ceil((pastDaysOfYear + startOfYear.getUTCDay() + 1) / 7);
    },
    getStartOfWeek(date) {
      const startOfWeek = new Date(date);
      const dayOfWeek = startOfWeek.getUTCDay();
      const diff = startOfWeek.getUTCDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1); 
      startOfWeek.setUTCDate(diff);
      return startOfWeek;
    },
    aggregateWeekly(data) {
      const weeklyData = {};

      data.forEach(item => {
        const date = new Date(item.date);
        const startOfWeek = this.getStartOfWeek(date).toISOString().split('T')[0]; 
        const weekYear = startOfWeek;

        if (!weeklyData[weekYear]) {
          weeklyData[weekYear] = {
            date: new Date(weekYear),
            personalized_sessions: 0,
            direct_revenue: 0,
            assisted_revenue:0,
            ctr:0,
            cr:0,
            impressions:0,
            recommended_purchases: 0
          };
        }

        weeklyData[weekYear].personalized_sessions += item.personalized_sessions;
        weeklyData[weekYear].direct_revenue += parseFloat(item.direct_revenue);
        weeklyData[weekYear].assisted_revenue += parseFloat(item.assisted_revenue);
        weeklyData[weekYear].ctr += item.ctr
        weeklyData[weekYear].impressions += item.impressions;
        weeklyData[weekYear].cr += item.cr;
        weeklyData[weekYear].cr += item.recommended_purchases;
      });

      this.weeklyTendings = Object.values(weeklyData);
    },
    aggregatePreviousWeekly(data) {
      const weeklyData = {};

      data.forEach(item => {
        const date = new Date(item.date);
        date.setDate(date.getDate() + this.periodLength); 
        const startOfWeek = this.getStartOfWeek(date).toISOString().split('T')[0]; 
        const weekYear = startOfWeek;

        if (!weeklyData[weekYear]) {
          weeklyData[weekYear] = {
            date: new Date(weekYear),
            personalized_sessions: 0,
            direct_revenue: 0,
            assisted_revenue:0,
            ctr:0,
            cr:0,
            impressions:0,
            recommended_purchases: 0
          };
        }

        weeklyData[weekYear].personalized_sessions += item.personalized_sessions;
        weeklyData[weekYear].direct_revenue += parseFloat(item.direct_revenue);
        weeklyData[weekYear].assisted_revenue += parseFloat(item.assisted_revenue);
        weeklyData[weekYear].ctr += item.ctr
        weeklyData[weekYear].impressions += item.impressions;
        weeklyData[weekYear].cr += item.cr;
        weeklyData[weekYear].cr += item.recommended_purchases;
      });

      this.weeklyPreviousTendings = Object.values(weeklyData);
    },
    loadNewData(){
      const loader = this.$loading.show();

      let trends = [];
      let oldTrends = [];
      let length = 0;

      if(this.groupButtonSelected == 'day'){
        trends = this.trendings;
        oldTrends = this.oldTrendings;
        length = this.periodLength;
      }else if(this.groupButtonSelected == 'week'){
        trends = this.weeklyTendings;
        oldTrends = this.weeklyPreviousTendings;
      }else if(this.groupButtonSelected == 'month'){
        trends = this.monthlyTendings;
        oldTrends = this.monthlyPreviousTendings;
      }
      
      if(!this.compare){
        this.series= [{
          name: this.auxValue.label,
          data:[]
        }]
      }else{
        this.series= [
          {
            name: this.auxValue.label,
            data: [],
          },
          {
            name: `${this.$t('common.previous_period')} (${this.periodLength} ${this.periodLength>1 ? this.$t('common.days_badge') : this.$t('common.day_badge')})`,
            data: [],
          },
        ]
      }

      switch(this.auxValue.id){
        case 'sessions':
          if(this.trendings){
            this.series[0].data = Array.from(trends, x => [ new Date(x.date).getTime() , x.personalized_sessions || 0]);
            this.series[0].data.sort((a, b) => a[0] - b[0]);

            if(this.compare && this.oldTrendings){
              this.series[1].data = Array.from(oldTrends, x => {
                let date = new Date(x.date);
                date.setDate(date.getDate() + length); 
                return [date.getTime(), x.personalized_sessions || 0];
              });
              this.series[1].data.sort((a, b) => a[0] - b[0]);
            }
          }
          this.$refs.TrendsChart?.updateSeries(this.series,true);
          loader.hide();
          break;
        case 'ctr': 
          if(this.trendings){
            this.series[0].data = Array.from(trends, x => [ new Date(x.date).getTime() , x.personalized_sessions == 0? 0 : x.ctr|| 0]);
            this.series[0].data.sort((a, b) => a[0] - b[0]);

            if(this.compare && this.oldTrendings){
              this.series[1].data = Array.from(oldTrends, x => {
                let date = new Date(x.date);
                date.setDate(date.getDate() + length); 
                return [date.getTime(), x.personalized_sessions == 0? 0 : x.ctr/x.personalized_sessions * 100|| 0];
              });
              this.series[1].data.sort((a, b) => a[0] - b[0]);
            }
          }

          this.$refs.TrendsChart?.updateSeries(this.series,true);
          loader.hide();
          break;
        case 'cr': 
          if(this.trendings){
            this.series[0].data = Array.from(trends, x => [ new Date(x.date).getTime() , x.cr || 0]);
            this.series[0].data.sort((a, b) => a[0] - b[0]);

            if(this.compare && this.oldTrendings){
              this.series[1].data = Array.from(oldTrends, x => {
                let date = new Date(x.date);
                date.setDate(date.getDate() + length); 
                return [date.getTime(), x.cr || 0];
              });
              this.series[1].data.sort((a, b) => a[0] - b[0]);
            }
          }
          this.$refs.TrendsChart?.updateSeries(this.series,true);
          loader.hide();
          break;
        case 'direct_revenue': 
          if(this.trendings){
            this.series[0].data = Array.from(trends, x => [ new Date(x.date).getTime() , x.direct_revenue || 0]);
            this.series[0].data.sort((a, b) => a[0] - b[0]);
            
            if(this.compare && this.oldTrendings){
              this.series[1].data = Array.from(oldTrends, x => {
                let date = new Date(x.date);
                date.setDate(date.getDate() + length); 
                return [date.getTime(), x.direct_revenue || 0];
              });
              this.series[1].data.sort((a, b) => a[0] - b[0]);
            }
            
          }
          this.$refs.TrendsChart?.updateSeries(this.series,true);
          loader.hide();
          break;
        case 'assisted_revenue': 
          if(this.trendings){
            this.series[0].data = Array.from(trends, x => [ new Date(x.date).getTime() , x.assisted_revenue || 0]);
            this.series[0].data.sort((a, b) => a[0] - b[0]);

            if(this.compare && this.oldTrendings){
              this.series[1].data = Array.from(oldTrends, x => {
                let date = new Date(x.date);
                date.setDate(date.getDate() + length); 
                return [date.getTime(), x.assisted_revenue || 0];
              });
              this.series[1].data.sort((a, b) => a[0] - b[0]);
            }
          }

          this.$refs.TrendsChart?.updateSeries(this.series,true);
          loader.hide();
          break;
        case 'recommended_purchases': 
          if(this.trendings){
            this.series[0].data = Array.from(trends, x => [ new Date(x.date).getTime() , x.recommended_purchases || 0]);
            this.series[0].data.sort((a, b) => a[0] - b[0]);

            if(this.compare && this.oldTrendings){
              this.series[1].data = Array.from(oldTrends, x => {
                let date = new Date(x.date);
                date.setDate(date.getDate() + length); 
                return [date.getTime(), x.recommended_purchases || 0];
              });
              this.series[1].data.sort((a, b) => a[0] - b[0]);
            }
          }

          this.$refs.TrendsChart?.updateSeries(this.series,true);
            
          loader.hide();
          break;
        }
    },
    selectButtonFilter(day){
      this.groupButtonSelected = day;
      this.loadNewData();
    },
    onValueChanged(){
      this.loadNewData();
    }
  },
  watch:{
    trendings: {
        immediate: true,
        handler(newVal){
          if(newVal){
            this.weeklyTendings = [];
            this.monthlyTendings = [];
            this.aggregateMonthly(newVal);
            this.aggregateWeekly(newVal);
            this.loadNewData();
          }
        }
    },
    oldTrendings: {
        immediate: true,
        handler(newVal){
          if(newVal){
            this.weeklyPreviousTendings = [];
            this.monthlyPreviousTendings = [];
            this.aggregatePreviousMonthly(newVal);
            this.aggregatePreviousWeekly(newVal);
            this.loadNewData();
          }
        }
    }
  }
};
</script>

<template>
  <div class="card-body">
    <div class="d-flex flex-wrap">
      <div class="d-flex flex-column">
        <h5 class="card-title me-2">Trendings</h5>
        <p class="small mb-0">{{getSubtitleText}}</p>
      </div>
      
      <div class="ms-auto d-inline-flex">
        <multiselect 
            :options="values"
            :placeholder="''"
            track-by="id" 
            label="label"
            :show-labels="false"
            :allow-empty="false"
            :multiple="false"
            v-model="auxValue"
            @input="onValueChanged()"
            style="width: 300px;"
            class="me-2"
            />
        <div class="btn-group btn-group-sm pl-2 w-70" role="group" style="width: 70%">
          <button type="button" :class="groupButtonSelected =='day' ? 'btn btn-primary' :'btn btn-outline-primary'" @click="selectButtonFilter('day')">{{$t('common.day')}}</button>
          <button type="button" :class="groupButtonSelected =='week' ? 'btn btn-primary' :'btn btn-outline-primary'" @click="selectButtonFilter('week')">{{$t('common.week')}}</button>
          <button type="button" :class="groupButtonSelected =='month' ? 'btn btn-primary' :'btn btn-outline-primary'" @click="selectButtonFilter('month')">{{$t('common.month')}}</button>
        </div>
      </div>
    </div>

    <hr class="mb-2" />

    <apexchart
      ref="TrendsChart"
      class="apex-charts"
      dir="ltr"
      height="350"
      :options="chartoptions"
      :series="series.length > 0 ? series : []"
      >
    </apexchart>
  </div>
</template>
